import React from "react";
import { Link } from "gatsby";

export default function Modal({
  open,
  setOpen,
  headingPoppins,
  paraOpenSans,
  message,
  errorMessage,
  refreshPage,
}) {
  const handleClose = () => {
    setOpen(false);
    if (refreshPage && !errorMessage) {
      refreshPage();
    }
  };

  return (
    <>
      {open && (
        <React.Fragment>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-primary">
            <div className="relative  my-6 mx-auto w-80">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white text-black outline-none  focus:outline-none py-3 ">
                <img
                  src="https://tomedes.gumlet.io/frontend/images/home-en/popup_assets/check_dark.svg"
                  alt="check_dark"
                  className="w-18 block mx-auto py-4"
                />
                <h3
                  className={`text-3xl font-semibold text-center ${
                    headingPoppins ? "font-secondary" : ""
                  }`}
                >
                  {errorMessage ? (
                    <span className="text-[#ff0000]">Error...</span>
                  ) : (
                    <>
                      <span className="text-orange">Thank</span> You!
                    </>
                  )}
                </h3>

                <div className="relative p-3 flex-auto">
                  <div
                    className={`text-blueGray-500 text-lg leading-relaxed text-center swal2-html-container ${
                      paraOpenSans ? "font-opensans" : ""
                    }`}
                    id="swal2-content"
                  >
                    {message ? (
                      <p dangerouslySetInnerHTML={{ __html: message }} />
                    ) : errorMessage ? (
                      errorMessage
                    ) : (
                      <p>
                        We'll reply in a few minutes. <br />
                        Meanwhile, you can set your translations up for success
                        with the{" "}
                        <Link
                          to="/tools/post-translation"
                          className="text-[#3A7AFF] underline underline-offset-4"
                        >
                          {" "}
                          free AI-powered translation quality 
                        </Link>
                        .
                      </p>
                    )}
                  </div>
                </div>
                <button
                  className="block mx-auto text-white bg-orange font-bold uppercase w-24 px-6 py-2 text-sm outline-none focus:outline-none mb-3 ease-linear transition-all duration-150"
                  type="button"
                  onClick={handleClose}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </React.Fragment>
      )}
    </>
  );
}
